import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchUserMetadata = async (userId: string) => {
  const response = await axios.get(`/api/fetch-user-metadata?userId=${userId}`);
  return response.data;
};

export const useFetchUserMetadata = (userId: string) => {
  return useQuery({
    queryKey: ["userMetadata", userId],
    queryFn: () => fetchUserMetadata(userId),
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });
};
