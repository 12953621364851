"use client";

import { UserProvider } from "@auth0/nextjs-auth0/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { AppRootProvider } from "./contexts/RootContext";
import { Auth0Provider } from "./ctx-auth0/Auth0Context";
import { DPQueryClientProvider } from "./providers/DPQueryClientProvider";

export function Providers({ children }: Readonly<{ children: ReactNode }>) {
  const client = new QueryClient();
  return (
    <UserProvider>
      <Auth0Provider>
        <QueryClientProvider client={client}>
          <DPQueryClientProvider>
            <AppRootProvider>{children}</AppRootProvider>
          </DPQueryClientProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </UserProvider>
  );
}
