import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchUserRole = async (userId: string) => {
  const response = await axios.get(`/api/fetch-user-role?userId=${userId}`);
  return response.data;
};

export const useFetchUserRole = (userId: string) => {
  return useQuery({
    queryKey: ["userRole", userId],
    queryFn: () => fetchUserRole(userId),
    enabled: !!userId, // Only fetch if userId is available
    staleTime: 5 * 60 * 1000, // Cache the response for 5 minutes
    retry: false, // Do not retry on failure
  });
};
